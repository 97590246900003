<template>
  <navigation-layout :footerEntries="navigationFooter">
    <centered-form-layout
      :title="caption"
      @submit.prevent="submit">
      <template v-if="!success">
        <form-message
          class="mt-4"
          v-if="error">
          {{ $t('auth.authenticate_error_message') }}
        </form-message>

        <div :class="{
          'mt-4 grid grid-cols-1 grid-rows-none gap-y-2 gap-x-6': true,
          'md:grid-cols-2': formFields && formFields.length > 8,
          'md:grid-cols-3': formFields && formFields.length > 12,
        }">
          <form-input
            :key="idx"
            v-for="(formField, idx) in formFields"
            v-model="payload[`${formField}`.toLowerCase()]">
            {{ $te(`generic.${formField}`.toLowerCase()) ? $t(`generic.${formField}`.toLowerCase()) : formField }}
          </form-input>
        </div>
        <form-input
          class="mt-4"
          type="select"
          :values="roles"
          v-model="payload.role"
          v-if="$route.params.slug && roles && roles.length > 1">
          {{ $t('generic.role') }}
        </form-input>
        <form-row>
          <c-button
            class="mt-4"
            type="submit"
            :disabled="loading">
            <template v-if="!loading">{{ $t('auth.register') }}</template>
            <div
              v-else
              class="flex justify-center">
              <icon
                size="medium"
                class="animate-spin"
                src="/icons/loading.svg">
              </icon>
            </div>
          </c-button>
        </form-row>
        <form-row class="mt-4">
          <div
            v-html="customRegisterText"
            class="flex justify-between"
          />
        </form-row>
      </template>
      <template v-else>
        <div class="p-4 text-center">
          <div class="flex justify-center">
            <icon
              size="xxxlarge"
              src="/icons/mail.svg">
            </icon>
          </div>
          <div class="mt-4">{{ $t('auth.register_success_message') }}</div>
        </div>
      </template>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../../mixin/design'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        baseURI: 'config/baseURI',
        registerRoles: 'config/registerRoles',
        defaultRegisterRole: 'config/defaultRegisterRole',
      }),
      caption() {
        return this.success
          ? this.$t('auth.register_success')
          : this.$t('auth.register')
      },
      roles() {
        return this.registerRoles && this.registerRoles[this.$helper.extractIdFromSlug(this.$route.params.slug)]
          ? this.registerRoles[this.$helper.extractIdFromSlug(this.$route.params.slug)]
          : []
      },
      formFields() {
        let formFieldsAsArray = this.$helper.findAttributeValue(this.category, 'CMS-Registration-Fields')?.split('~')
          .map(str => str.replace(' ', ''))
          .filter(str => str)
        if (formFieldsAsArray) {
          return formFieldsAsArray
        }
        return [
          'email',
        ]
      },
      customRegisterText() {
        return this.findCustomText('register')
      }
    },
    data() {
      return {
        payload: {},
        error: null,
        loading: null,
        success: null,
        category: null,
        navigationFooter: [],
      }
    },
    /**
     * Applies design and updates the ui
     * @return {Promise<void>}
     */
    async created() {
      this.category = await this.applyDesign()
      this.navigationFooter = await this.$helper.buildFooter(this.$api, this.category)
      this.$emit('updateUI')
    },
    methods: {
      async submit() {
        this.error = false
        this.loading = true
        let path = this.$route.params.slug
          ? `${this.baseURI}/${this.$route.params.slug}`
          : `${this.baseURI}/`

        // Select role (client specific)
        let role = this.defaultRegisterRole
        const slugId = this.$helper.extractIdFromSlug(this.$route.params.slug)
        if (this.registerRoles
          && this.registerRoles[slugId]) {
          if (this.roles && this.roles.length === 1) {
            this.payload.role = this.roles[0].id
          }
          role = this.registerRoles[slugId].find(r => r.id == this.payload.role)
        }

        let response = await this.$api.register(this.payload, path, role)
        if (response && response.status === 200) {
          this.success = true

          // Creates a new password reset mail, so set the password for the user
          // for the first time.
          const jumpURL = this.$route.params.slug
            ? `${window.location.origin}/${this.$route.params.slug}/auth/password`
            : `${window.location.origin}/auth/password`

          let response = await this.$api.requestPasswordReset(this.login, jumpURL)
          if (response && response.ok) {
            response = await response.json()

            await this.$api.sendPasswordResetMail({
              email: response.email,
              url: response.htmlaction,
              subject: this.$t('passwordemail.subject'),
              title: this.$t('passwordemail.title'),
              subtitle: this.$t('passwordemail.subtitle'),
              buttontitle: this.$t('passwordemail.buttontitle'),
              primaryColor: this.$store.getters['app/primaryColor'],
              tertiaryColor: this.$store.getters['app/tertiaryColor'],
              secondaryColor: this.$store.getters['app/secondaryColor'],
              customerId: this.$helper.extractIdFromSlug(this.$route.params.slug),
            })
          }
          return
        }
        this.loading = false
        this.error = true
      },
    },
    inject: [
      '$api',
      '$helper',
    ],
    mixins: [
      DesignMixin
    ]
  }
</script>

<style lang="scss" scoped>
  .authentication {
    @apply
    pt-4
    flex
    w-full
    h-full
    md:pt-0
    md:items-center
    justify-center;

    .form {
      @apply
      p-6
      w-full
      border
      rounded
      max-w-lg
      shadow-xl;
    }
  }
</style>